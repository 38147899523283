import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Offers from "../components/offers"

const OffersPage = () => {
  const offers = [
  {
      header: "AMP Bank - $2,000 LMI Rebate",
      details:
        "$2,000 Lenders Mortgage Insurance Rebate, with no minimum loan amount. LVR must be greater than 80% at settlement. Available for applications made between 19 June 2024 and 31 August 2024 and must settle on or before 30 November 2024. Not available on refinances or internal restructures and switches.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "31 August 2024",
      callToAction: "/contact",
    },
    {
      header: "ANZ - Up to $2,000 Cashback Offer",
      details:
        "Refinance your home loan of $250K+ to ANZ and receive $2,000 cashback if the application is assessed at an LVR of 80% or less.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "Until Withdrawn",
      callToAction: "/contact",
    },
    {
      header: "ANZ - $3,000 Cashback for 1st Home Buyers",
      details:
        "Receive $3,000 to help with first home buying costs. Available to eligible first home buyers with an ANZ home loan of $250k+. Loans must settle within 180 days of the application.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "Until Withdrawn",
      callToAction: "/contact",
    },
  {
      header: "Liberty - Fee waiver for AAA Refinance Applications",
      details:
        "For a limited time, Liberty is waiving the $695 review fee for AAA borrowers submitting new SMSF refinance applications.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "Until Withdrawn",
      callToAction: "/contact",
    },
    {
      header: "ME Bank - $3,000 Refinance Cashback Offer",
      details:
        "Receive a $3,000 Cashback for eligible refinances of $700k+ with an LVR equal to or less than 80%. Excludes Construction and Interest Only loans and applies to applications submitted after 8 Sep 2023 and settle within 120 days of the loan application date.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "Until Withdrawn",
      callToAction: "/contact",
    },
  {
      header: "Suncorp Bank - Annual Package Fee Refund Offer  ",
      details:
        "New Suncorp Home Package Plus customers are eligible to receive a refund of the annual fee for the lifetime of the loan.  Applies to new home loans or refinancing existing home loans.",
      disclaimer:
        "Conditions apply and offer may be amended or withdrawn at any time.",
      expiry: "Until Withdrawn",
      callToAction: "/contact",
    },
   ]

  return (
    <Layout>
      <SEO title="Offers" />
      <Offers offers={offers} />
    </Layout>
  )
}

export default OffersPage
