import React, { useContext } from "react"
import Banner from "./banner"
import "./offers.css"
import CustomAccordion from "./accordion"
import CustomButton from "./button"
import { GlobalContext } from "./../context/provider.js"
import { navigate } from "gatsby"

const Offers = ({ offers }) => {
  const { dispatch } = useContext(GlobalContext)

  return (
    <div className="offers">
      <Banner />
      <div className="offers-body">
        <div className="offers-body-header">
          <h1>
            Home loan offers
            <br />
            and <strong>exclusive deals</strong>
          </h1>
          <p>
            With a national network of lenders, we have access to the latest
            cashback offers, competitive interest rates and other incentives
            from Australia’s leading and most trusted lenders. See how your
            current rate compares against the market, explore the refinancing
            options or discover which lenders offer the right options for your
            new loan.
          </p>
        </div>
        <div className="offers-accordion">
          {offers.map((item, index) => (
            <CustomAccordion
              key={index}
              header={item.header}
              body={
                <>
                  <h6>{item.details}</h6>
                  <p className="my-4">Valid Until: {item.expiry}</p>
                  <p className="my-3">{item.disclaimer}</p>
                  <CustomButton
                    variant="secondary"
                    className="offers-button"
                    onClick={() => {
                      navigate(item.callToAction)
                    }}
                  >
                    Learn more
                  </CustomButton>
                </>
              }
            />
          ))}
        </div>
        <div className="offers-banner">
          <div className="offers-banner-header">Let’s Compare n Save</div>
          <div className="offers-banner-subheader">
            Head to our home loan calculator to compare 100s of loans from
            Australia’s leading lenders in seconds.
          </div>
          <CustomButton
            variant="secondary"
            className="offers-banner-button"
            onClick={() => {
              dispatch({ type: "SET_REFINANCE", refinance: false })
              navigate("/product")
            }}
          >
            Show me the savings
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default Offers
